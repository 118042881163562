.linkDocumentsWindow {
  width: 840px;
  .windowBody {
    padding-right: 15px;
    padding-left: 15px;
  }
  .searchInputGroup {
    max-width: 60%;
  }
  .tableColumn {
    align-items: center;
    min-height: 15em;
    max-height: 24em;
    margin-top: 1em;
    .tableWrapper {
      width: 100%;
      max-height: 100%;
      overflow-y: auto;
      .documentsTable {
        width: 100%;
      }
    }
  }
}
