.duplicatedDocumentsWindow {
  width: 840px;
  .windowBody {
    padding-right: 15px;
    padding-left: 15px;
  }
  .tableColumn {
    $maxHeight: 25.8em;
    align-items: center;
    min-height: 15em;
    max-height: $maxHeight;
    margin-top: 1em;
  }
}
