@import "config";

.editDocumentWindowContactBlock {
  .contactNameRow {
    flex: none;
    font-size: 1.3em;
  }
  .contactNameRow {
    flex: none;
    font-size: 1.3em;
    > div {
      > div {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .unlinkButtonRow {
    margin-top: 0.4em !important;
    .unlinkButton {
      padding: 0.8em;
    }
  }
  .findContactMessage {
    svg {
      font-size: 1.6em;
      cursor: pointer;
      &:hover {
        color: $uiHighlightColor;
      }
    }
  }
  .messageRow {
    flex: none !important;
    padding: 0.8em 0;
    text-align: center;
  }
}
