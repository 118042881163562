@import "config";

.multiAmountWidget {
  flex: 1;
  font-size: 1em;
  &:global(.show) {
    > input {
      position: relative;
      z-index: 1;
      border-color: $uiNeutralColor;
    }
  }
  .menu {
    z-index: 1010;
    width: 30em;
    margin-top: -$contentDefaultIndent / 2;
    margin-left: -$contentDefaultIndent / 2;
    padding: $contentDefaultIndent;
    font-size: 1em;
    > hr {
      margin: $contentDefaultIndent 0;
    }
    > div {
      display: flex;
      align-items: center;
      max-height: none;
      &:first-child {
        > div {
          overflow: hidden;
          text-align: center;
          text-overflow: ellipsis;
        }
      }
      &:last-child {
        justify-content: center;
        margin-top: $contentDefaultIndent * 2;
        > button {
          flex: none;
          min-width: 50%;
        }
      }
      > * {
        flex: 3;
      }
      > span {
        flex: none;
        width: 2em;
        margin-right: $contentDefaultIndent;
        font-weight: 500;
        text-align: right;
      }
      > input {
        min-width: 0;
        padding: $contentDefaultIndent !important;
        border-radius: $contentDefaultIndent !important;
        background-image: none !important;

        &:global(.is) {
          &-invalid {
            background-image: none;
            &:not([disabled]) {
              border-color: $uiNegativeColor !important;
            }
          }
        }
        + input {
          margin-left: $contentDefaultIndent / 2;
        }
        &.correctionInput {
          flex: auto;
        }
      }
      + div {
        margin-top: $contentDefaultIndent / 2;
      }
    }
  }
}
