@import "config";

.statusFilter {
  width: 100%;
  white-space: nowrap;
  button[type=button] {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    &:last-child {
      flex: none;
    }
    svg {
      flex: none;
      font-size: 1.4em;
    }
    span {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

@media (max-width: $simplifiedLayoutMaxWidth) {
  .statusFilter {
    button[type=button] {
      flex: 1;
    }
  }
}
