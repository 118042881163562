@import "config";

.editDocumentWindowContainer {
  $maxContentHeight: 78em;
  .form {
    width: 100%;
    height: 100%;
    .row {
      min-height: 100%;
    }
  }
  &.editMode {
    overflow-y: scroll;
    background-color: $uiWhiteColor;
    :global(.dropdown) {
      &-menu {
        z-index: 1010;
        min-width: 20em;
        margin-top: -$contentDefaultIndent / 2;
        margin-left: -$contentDefaultIndent / 2;
      }
    }
    .modalWindow {
      display: flex;
      width: 100%;
      min-width: $contentMinWidth;
      max-width: none;
      min-height: 100%;
      padding: 0;
      .modalBody {
        display: flex;
        align-items: center;
        width: 100%;
        min-width: $contentMinWidth;
        max-width: $contentMaxWidth;
        height: 100%;
        margin: 0 auto;
        padding: 0.8em ($contentDefaultIndent * 4) - 1 $contentDefaultIndent * 2;
        > div {
          display: flex;
          width: 100%;
          height: 100%;
          > form {
            width: 100%;
            min-height: 100%;
            > div {
              min-height: 100%;
            }
          }
        }
      }
      .dataFieldsColumn {
        min-width: 0;
        max-width: 40em;
        max-height: 78em;
      }
    }
    &:global(.mode) {
      &-lineItems,
      &-history,
      &-comments {
        .modalWindow {
          .dataFieldsColumn {
            max-height: none;
          }
        }
      }
      &-contact {
        .modalWindow {
          .dataFieldsColumn {
            max-height: 57em;
          }
        }
      }
    }
    :global(.modal) {
      &-header,
      &-footer {
        border-radius: 0;;
      }
    }
  }
  .modalWindow {
    width: 620px;
    .titleRow {
      flex-wrap: nowrap;
      align-items: center;
      height: 3em;
    }
    .autoUploadEmailGroup {
      margin-top: 1.6em;
      text-align: center;
      b {
        font-size: 1.2em;
      }
    }
    .dataFieldsColumn {
      display: flex;
      flex: 0.5;
      flex-direction: column;
      margin-right: 0.8em;
      > div:last-child {
        flex: auto;
        flex-direction: column;
        margin-left: -1.6em;
      }
      [data-placeholder] {
        flex: 3;
        min-height: $contentDefaultIndent;
      }
      :global(.form) {
        &-control {
          border-radius: 0;
        }
      }
      :global(.input) {
        &-group-text {
          border-radius: 0;
        }
        &-group-prepend {
          > span {
            width: 112px;
            padding-right: $contentDefaultIndent / 2;
            padding-left: $contentDefaultIndent / 2;
          }
        }
        &-group-append {
          background-color: $uiWhiteColor;
          > span {
            width: 4em;
            padding: 0;
            background: $uiWhiteColor;
            font-weight: 500;
            > span {
              justify-content: center;
              > svg {
                font-size: 1.4em;
              }
            }
          }
        }
      }
      :global(.form) {
        &-row {
          flex: 2;
          margin-left: 0;
          + div {
            margin-top: -1px;
          }
          &:first-child {
            :global(.input) {
              &-group-prepend {
                > span {
                  border-top-left-radius: $contentDefaultIndent;
                }
              }
              &-group-append {
                > span {
                  border-top-right-radius: $contentDefaultIndent;
                }
              }
            }
            input:last-child,
            select:last-child,
            button:last-child {
              border-top-right-radius: $contentDefaultIndent;
            }
          }
          &:last-child {
            :global(.input) {
              &-group-append {
                > span {
                  border-bottom-right-radius: $contentDefaultIndent;
                }
              }
              &-group-prepend {
                > span {
                  border-bottom-left-radius: $contentDefaultIndent;
                }
              }
            }
            input,
            select {
              &:last-child {
                border-bottom-right-radius: $contentDefaultIndent;
              }
            }
            :global(.form) {
              &-control {
                &:last-child {
                  border-bottom-right-radius: $contentDefaultIndent;
                }
              }
            }
          }
        }
      }
    }
    .attachmentColumn {
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-width: 0;
      .downloadLinkContainer,
      .externalLinkContainer {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        > a {
          font-weight: 500;
          > svg {
            font-size: 1.3em;
          }
        }
      }
      .externalLinkContainer {
        margin-left: auto;
        padding-left: 1em;
      }
      .pdfViewerContainer {
        position: relative;
        flex: auto;
        overflow: hidden;
        border: 1px solid $uiBorderColor;
        border-radius: $contentDefaultIndent;
        background-color: lighten($uiNeutralColor, 10%);
      }
    }
    .amountInputGroup {
      input,
      :global(.dropdown) {
        flex: 3;
      }
      :global(.dropdown) {
        margin-right: -1px;
      }
    }
    .bankInputGroup {
      select {
        flex: 3;
      }
      input {
        min-width: 4em;
      }
    }
    .selectContactInput,
    .selectLocationInput {
      position: relative;
      z-index: 1;
      > button {
        width: 4em;
        border-radius: 0;
      }
    }
    .expandableInput {
      height: 100%;
      &:not([disabled]) {
        background-color: $uiWhiteColor;
        background-image: linear-gradient(225deg, $uiDarkColor 0.5em, $uiWhiteColor 0.5em);
        background-position: right top;
        cursor: pointer !important;
      }
      &:global(.is) {
        &-invalid {
          &:not([disabled]) {
            $invalidColor: lighten($uiNegativeColor, 55%);
            background-image: linear-gradient(225deg, $uiNegativeColor 0.5em, $invalidColor 0.5em);
          }
        }
      }
    }
    .cloudDocumentsPickers {
      display: flex;
      > div {
        + div {
          margin-left: $contentDefaultIndent;
        }
      }
    }
  }
  .modalHeaderWrapper,
  .modalFooterWrapper {
    position: relative;
    position: sticky;
  }
  .modalHeaderWrapper {
    top: 0;
  }
  .modalFooterWrapper {
    bottom: 0;
  }
  .commentsBlockWrapper {
    position: relative;
    min-height: 20rem;
    .commentsBlock {
      position: absolute;
      top: 0;
      right: -$contentDefaultIndent;
      left: $contentDefaultIndent;
      height: 100%;
    }
  }
  .checkbox {
    pointer-events: none;
  }
  .merge {
    margin-right: -34px;
    margin-left: -34px;
    padding-top: $contentDefaultIndent * 2;
    border-top: 1px solid $uiBorderColor;
  }
}

body[data-introjs] {
  .modalFooterWrapper {
    margin: 16px auto;
    > div {
      padding: 0 !important;
      border: none;
      background: none !important;
    }
  }
}
