@import "config";

.previewCell {
  display: flex;
  flex: auto;
  justify-content: center;
  padding: 0;
  .preview {
    width: 2.84em;
    min-width: 2.84em;
    height: 4em;
    border-radius: $contentDefaultIndent / 2;
  }
}
