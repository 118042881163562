@import "config";

@mixin errorBlockStyles {
  max-width: 100%;
  margin: 1em;
  padding: 1em;
  border-radius: $contentDefaultIndent;
  background-color: $uiWhiteColor;
  color: $uiNegativeColor;
  font-size: 1.4em;
  text-align: center;
}

.pdfViewer {
  position: absolute;
  width: 100%;
  height: 100%;
  .imagePreloader {
    position: absolute;
    width: auto;
    height: auto;
  }
  > div {
    border: none;
    :global(.viewer) {
      &-doc-error-text {
        @include errorBlockStyles();
      }
      &-spinner {
        circle {
          stroke: $uiPreloaderColor !important;
        }
      }
      &-inner-page {
        padding: $contentDefaultIndent / 2;
      }
      &-icon {
        $size: 1.3em;
        width: $size;
        height: $size;
      }
      &-page-layer {
        overflow: hidden;
        border-radius: $contentDefaultIndent / 2;
        background-color: $uiWhiteColor;
        box-shadow: 0 0 4px rgba($uiBlackColor, 0.15);
      }
      &-exit-fullscreen {
        z-index: 1;
      }
      &-sidebar-tabs {
        display: none;
      }
      &-layout-sidebar {
        border: none;
        background-color: rgba($uiBlackColor, 0.1);
      }
      &-layout-main {
        overflow: auto;
      }
      &-layout-toolbar {
        background-image: $uiHeaderGradient;
      }
      &-toolbar {
        > div {
          flex: 1;
        }
      }
      &-tooltip-body {
        display: none;
      }
      &-toolbar-left {
        > div {
          &:nth-child(1),
          &:nth-child(2) {
            display: none;
          }
        }
      }
      &-toolbar-right {
        justify-content: flex-end;
        > div {
          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4) {
            display: none;
          }
        }
      }
      &-menu {
        > li {
          &:nth-last-child(1),
          &:nth-last-child(2),
          &:nth-last-child(3) {
            display: none;
          }
        }
      }
    }
  }
  > .errorBlock {
    @include errorBlockStyles();
  }
}
