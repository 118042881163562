@import "config";
@import "animations";

.documentsPage {
  .buttonsContainer {
    display: flex;
    > div {
      + div {
        margin-left: $contentDefaultIndent;
      }
    }
  }
  .cardHeader {
    align-items: flex-start;
    padding-bottom: $contentDefaultIndent;
    > div:first-child {
      min-width: 0;
    }
    .statusDescription {
      margin-top: $contentDefaultIndent;
      opacity: 0.75;
      line-height: 1;
    }
  }
  .cardBody {
    padding: $contentDefaultIndent 0 $contentDefaultIndent * 2 0;
    .actionsButtons {
      > button {
        padding: 0.65em 0.85em;
      }
    }
    button {
      [data-wait] {
        animation: rotate 2s linear infinite;
      }
    }
  }
}
