@import "config";

.editDocumentWindowHistoryBlock {
  [data-placeholder] {
    flex: auto !important;
    max-height: 2em;
    line-height: 1;
  }
  :global(.form) {
    &-row {
      flex: none !important;
    }
  }
  .eventBlock {
    display: flex;
    width: 100%;
    line-height: 1.2;
    .icon {
      margin-right: 0.5em;
      padding-right: 0.5em;
      border-right: solid 2px;
      font-size: 2em;
    }
    .content {
      min-width: 0;
      .titleBlock {
        > div {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .title {
          font-size: 1.3em;
          font-weight: 500;
        }
        .subTitle {
          overflow: hidden;
          line-height: 1.5;
          > svg {
            font-size: 1.2em;
          }
          .user {
            opacity: 0.8;
            font-weight: 500;
          }
          .date {
            opacity: 0.5;
          }
        }
      }
      .fieldsBlock {
        padding-top: 0.5em;
        word-break: break-all;
        a {
          color: $uiDarkColor;
          text-decoration: underline;
          &:hover {
            color: $uiHighlightColor;
            text-decoration: none;
          }
        }
        > div {
          display: flex;
          align-items: center;
          padding-top: 0.5em;
          > svg {
            flex: none;
            font-size: 1.2em;
          }
          .fieldName {
            font-weight: 500;
          }
          .oldValue {
            opacity: 0.5;
          }
          .deletedValue {
            text-decoration: line-through;
          }
        }
      }
    }
  }
  .noDataContent {
    margin: auto !important;
  }
}
