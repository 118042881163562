@import "config";

@mixin counterBadgeMixin($name, $color) {
  $animationName: #{$name}Pulse;
  $shadowColorA: rgba($uiWhiteColor, 0.85);
  $shadowColorB: rgba($color, 0.7);
  @keyframes #{$animationName} {
    0% {
      box-shadow: 0 0 0 0 #{$shadowColorA}, 0 0 0 0 #{$shadowColorB};
    }
    70% {
      box-shadow: 0 0 0 2px #{$shadowColorA}, 0 0 0 6px #{$shadowColorB};
    }
    100% {
      box-shadow: 0 0 0 0 #{$shadowColorA}, 0 0 0 0 #{$shadowColorB};
    }
  }
  background-color: $color;
  &[data-animation] {
    animation: #{$animationName} 0.4s 2;
  }
}

.statusFilterCounter {
  flex: none;
  max-width: 65%;
  margin-left: 0.6em;
  border: 1px solid;
  &[data-zero] {
    opacity: 0.5;
    text-shadow: none;
  }
  &:not([data-zero]) {
    border-color: transparent;
    color: $uiWhiteColor;
    &[data-status] {
      &[data-status=toExtract] {
        @include counterBadgeMixin("warning", $uiWarningColor);
      }
      &[data-status=toReconcile] {
        @include counterBadgeMixin("special", $uiSpecialColor);
      }
      &[data-status=toReview] {
        @include counterBadgeMixin("highlight", $uiHighlightColor);
      }
      &[data-status=toReport] {
        @include counterBadgeMixin("positive", $uiPositiveColor);
      }
      &[data-status=exported] {
        @include counterBadgeMixin("dark", $uiMediumDarkColor);
      }
      &[data-status=excluded] {
        @include counterBadgeMixin("accent", $uiAccentColor);
      }
      &[data-status=needReaction] {
        @include counterBadgeMixin("warning", $uiWarningColor);
      }
    }
    &:not([data-status]) {
      background-color: $uiBlackColor;
    }
  }
}
