@import "nlib/config";

.tooltip {
  position: absolute;
  z-index: 1;
  padding: $contentDefaultIndent / 2 $contentDefaultIndent;
  border-radius: $uiBorderRadius;
  background-color: $uiWhiteColor;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.04), -2px 2px 4px rgba(0, 0, 0, 0.04);
  font-weight: normal;
  &::after {
    content: "";
    position: absolute;
    border-style: solid;
  }
  &.top {
    bottom: 100%;
    left: 50%;
    transform: translate(-50%, -$contentDefaultIndent);
    &::after {
      top: 100%;
      left: 50%;
      transform: translate(-50%, 0);
      border-width: 4px 4px 0;
      border-color: $uiWhiteColor transparent transparent;
    }
  }
  &.bottom {
    top: 100%;
    left: 50%;
    transform: translate(-50%, $contentDefaultIndent);
    &::after {
      bottom: 100%;
      left: 50%;
      transform: translate(-50%, 0);
      border-width: 0 4px 4px;
      border-color: transparent transparent $uiWhiteColor;
    }
  }
}
