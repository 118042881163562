@import "config";

.includeExclude {
  display: flex;
  flex-direction: column;
  max-height: 240px;
  overflow: hidden;
  ::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: $uiNeutralColor;
    &:active {
      background-color: $uiHighlightColor;
    }
  }
  ::-webkit-scrollbar {
    width: 3px;
    height: 3px;
    border-radius: 2px;
    background-color: $uiBorderColor;
  }
  .label {
    font-size: 0.75rem;
    font-weight: 500;
  }
  .input {
    input {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  .row {
    display: flex;
    flex: auto;
    overflow: hidden;
  }
  .col {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: $contentDefaultIndent * 1.5;
    overflow: hidden;
    border: 1px solid $uiBorderColor;
    border-top: none;
    border-bottom-left-radius: $uiBorderRadius;
    .head {
      display: flex;
      justify-content: space-between;
      margin-bottom: $contentDefaultIndent * 1.5;
      padding-bottom: $contentDefaultIndent * 1.5;
      border-bottom: 1px solid $uiBorderColor;
      font-size: 0.75rem;
      white-space: nowrap;
      .action {
        margin-left: $contentDefaultIndent;
        color: $uiHighlightColor;
        cursor: pointer;
        &:hover {
          filter: brightness(0.75);
        }
      }
    }
    + .col {
      border-left: none;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: $uiBorderRadius;
    }
  }
  .content {
    overflow: auto;
  }
  .item {
    display: flex;
    align-items: center;
    padding: $contentDefaultIndent / 4 0;
    overflow: hidden;
    .checkbox {
      width: 100%;
      min-height: 1.5rem;
      word-break: break-all;
    }
  }
  .placeholder {
    color: $uiPlaceholderColor;
  }
  .label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
