.documentsTable {
  width: 100%;
  max-height: 100%;
  overflow: auto;
  table {
    width: 100%;
  }
  .duplicatesTable {
    :global(.rt) {
      &-thead {
        display: none;
      }
    }
  }
  .message {
    padding: 1em;
    text-align: center;
    svg {
      font-size: 1.2em;
    }
  }
}
