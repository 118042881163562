.uploadedByCell {
  position: relative;
  cursor: default;
  .tooltip {
    display: none;
    .content {
      max-width: 16rem;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &:hover {
    .tooltip {
      display: block;
    }
  }
}
