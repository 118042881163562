@import "config";
@import "animations";

$animationDuration: 0.2s;

.documentPreview {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 5em;
  min-width: 5em;
  height: 7em;
  overflow: hidden;
  border: 1px solid $uiBorderColor;
  border-radius: $contentDefaultIndent;
  color: darken($uiNeutralColor, 5%);
  cursor: default;
  &:hover {
    .actions {
      transform: translate(0, 0);
      opacity: 1;
    }
  }
  &.clickable {
    cursor: pointer;
  }
  [data-wait] {
    animation: rotate 1s linear infinite;
  }
  .preview {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    text-transform: uppercase;
  }
  .actions {
    display: flex;
    position: absolute;
    bottom: $contentDefaultIndent / 2;
    left: 0;
    justify-content: space-around;
    width: 100%;
    transform: translate(0, 50%);
    transition: opacity $animationDuration, transform $animationDuration;
    opacity: 0;
    .edit,
    .download,
    .detach {
      display: flex;
      padding: 0.3em;
      border-radius: 50%;
      background-color: rgba($uiBlackColor, 0.5);
      color: $uiWhiteColor;
      font-size: 0.75em;
      cursor: pointer;
      svg {
        font-size: 1rem;
      }
    }
    .edit {
      &:hover {
        background-color: $uiHighlightColor;
      }
    }
    .download {
      &:hover {
        background-color: $uiPositiveColor;
      }
    }
    .detach {
      &:hover {
        background-color: $uiNegativeColor;
      }
    }
  }
  .loadingIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    svg {
      font-size: 1.8em;
    }
  }
  svg {
    &.error {
      color: $uiNegativeColor;
    }
  }
}
