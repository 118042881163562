@import "config";

.transactionsTable {
  svg {
    font-size: 1.2em;
  }
  .statusCell,
  .idCell,
  .addressCell,
  .dateCell,
  .amountCell,
  .descriptionCell,
  .actionsCell {
    flex: none;
    padding-right: $contentDefaultIndent / 2;
    padding-left: $contentDefaultIndent / 2;
    white-space: nowrap;
    span,
    a {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .tableHead {
    .statusCell,
    .idCell,
    .addressCell,
    .dateCell,
    .amountCell,
    .descriptionCell,
    .actionsCell {
      padding-top: $contentDefaultIndent / 2;
      padding-bottom: $contentDefaultIndent / 2;
    }
  }

  .statusCell {
    justify-content: center;
    width: 64px;
  }
  .idCell {
    width: 130px;
    padding-left: $contentDefaultIndent * 2;
  }
  .addressCell {
    width: 130px;
  }
  .dateCell {
    width: 100px;
  }
  .amountCell {
    width: 130px;
  }
  .descriptionCell {
    flex: auto;
    min-width: 0;
  }
  .actionsCell {
    flex: 1;
    width: 80px;
    padding-right: $contentDefaultIndent * 2;
    .actionsButtons {
      justify-content: flex-end;
      width: 3.5rem;
      button {
        flex: none;
      }
    }
  }
}
