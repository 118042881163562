@import "config";

.documentsTablePopup {
  position: relative;
  position: sticky;
  z-index: 500;
  bottom: 2em;
  max-width: calc(100% - #{$contentDefaultIndent * 4});
  margin: auto;
  margin-top: auto;
  padding: 1em;
  border-top: 3px solid $uiPositiveColor;
  border-radius: $contentDefaultIndent;
  opacity: 1;
  background-color: $uiWhiteColor;
  box-shadow: 0 0.5rem 4rem rgba($uiBlackColor, 0.11),
    0 10px 20px rgba($uiBlackColor, 0.05),
    0 2px 3px rgba($uiBlackColor, 0.06);
  text-align: center;
  &:global(.transition) {
    &-enter {
      transform: translate3d(0, 100%, 0);
      opacity: 0;
    }
    &-enter-active,
    &-exit {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
    &-exit-active {
      transform: translate3d(0, 100%, 0);
      opacity: 0;
    }
    &-enter-active,
    &-exit-active {
      transition: opacity 300ms, transform 300ms;
    }
  }
  > span {
    display: inline-block;
    padding: 0.6em 0;
    font-size: 1.1em;
  }
  &,
  .actionButton {
    font-weight: 500 !important;
  }
  .actionButton {
    max-width: 25vw;
    margin-left: 1em;
    overflow: hidden;
    font-size: 1em !important;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
