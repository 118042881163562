@import "config";

.editVatClassificationModal {
  width: 40rem;
  .includeExclude {
    max-height: 24.25rem;
  }
  .footer {
    display: flex;
    justify-content: flex-end;
    margin-top: $contentDefaultIndent;
    button {
      border: none;
    }
  }
  .dropdown {
    right: 0;
    left: auto;
    width: 24em;
    padding: $contentDefaultIndent;
    font-size: $uiDesktopFontSize;
    :global(.input) {
      &-group {
        &-text {
          width: 6.5em;
          line-height: 1;
        }
        select {
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
        }
      }
    }
    .buttonWrapper {
      display: flex;
      justify-content: center;
      margin-top: $contentDefaultIndent;
      button {
        + button {
          margin-left: $contentDefaultIndent;
        }
      }
    }
  }
}
