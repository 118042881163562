@import "config";

.editDocumentWindowHeader {
  padding: $contentDefaultIndent $contentDefaultIndent * 2;
  border-radius: $contentDefaultIndent $contentDefaultIndent 0 0;
  background-image: $uiHeaderGradient;
  backdrop-filter: blur(6px);
  .modalTitle {
    display: flex;
    align-items: center;
    width: 100%;
    > div {
      height: 100%;
      > button {
        padding: 0.6em 0.8em;
        &:first-child {
          margin-left: $contentDefaultIndent * 2;
        }
        svg {
          font-size: 1.4em;
        }
      }
    }
    .text {
      min-width: 0;
      margin-right: auto;
      white-space: nowrap;
      > h5,
      > div {
        overflow: hidden;
        text-overflow: ellipsis;
      }
      h5 {
        margin-bottom: $contentDefaultIndent / 2;
        b ~ span[data-muted] {
          opacity: 0.75;
        }
      }
    }
    .switchButtons {
      display: flex;
      flex: none;
      align-items: center;
      div {
        width: 4em;
        text-align: center;
      }
    }
  }
}
