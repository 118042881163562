@import "config";

.editDocumentWindowModeSelector {
  width: 100%;
  height: 100%;
  padding-right: 0.4em;
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-color: $uiBorderColor;
    box-shadow: none;
    svg {
      flex: none;
      font-size: 1.2em;
    }
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .lineItemsCounterBadge {
    background-color: $uiDarkColor;
  }
}

@media (max-width: 1400px) {
  .editDocumentWindowModeSelector {
    button[type=button] {
      svg + span {
        display: none;
      }
    }
  }
}
