@import "config";

.selectCurrencyInput {
  display: flex;
  flex: 1;
  width: 100%;
  &:not(:first-child) {
    .currencyInput {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
  &.withButton {
    .currencyInput {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  .currencyInput {
    &:global(.is) {
      &-valid {
        padding-right: 1rem;
        background-image: none;
      }
    }
    &.warning {
      border-color: $uiWarningColor !important;
      box-shadow: 0 5px 11px rgba($uiWarningColor, 0.1) !important;
    }
  }
}
