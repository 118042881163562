@import "config";

.changeStatusSelector {
  > div {
    button {
      svg {
        font-size: 1.2em;
        &[data-status] {
          &[data-status=toExtract] {
            color: $uiWarningColor;
          }
          &[data-status=toReconcile] {
            color: $uiSpecialColor;
          }
          &[data-status=needReaction] {
            color: $uiWarningColor;
          }
          &[data-status=toReview] {
            color: $uiHighlightColor;
          }
          &[data-status=toReport] {
            color: $uiPositiveColor;
          }
          &[data-status=exported] {
            color: $uiMediumDarkColor;
          }
          &[data-status=excluded] {
            color: $uiAccentColor;
          }
        }
      }
    }
  }
}

@media (max-width: $simplifiedLayoutMaxWidth) {
  .changeStatusSelector {
    > button {
      svg + span {
        display: none;
      }
    }
  }
}
